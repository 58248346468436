import React, { useState, useRef } from 'react'
import { motion } from 'framer-motion'

//import global styles
import {
    PaddingContainer,
    FlexContainer,
    Heading,
    ParaText,
    BlueText,
    IconContainer,
    //LogoContainer,
    //InnerLogoContainer,
    SendButton,
} from '../styles/Global.styled';

//import showcase styles
import { 
    ShowcaseImageCard,
    ShowcaseParticleContainer,
    StyledLink
} from '../styles/Showcase.styled';

//import react-icons
import { 
    BsTwitter, 
    //BsInstagram, 
    //BsYoutube,
    BsTelegram
} from "react-icons/bs";

//import asset
import longBoiOverlay from '../assets/longboi.png';
//import WSSLogo from '../assets/WSSLogo.png';
//import PitchersLogo from '../assets/PitchersLogo.png';
//import FamilyPhoto from '../assets/family-photo.jpg';
//import CTLogo from '../assets/CTLogo.png';
import { fadeInLeftVariant, fadeInRightVariant } from '../utils/Variants';





const Showcase = () => {

    const [uploadedImage, setUploadedImage] = useState(null);
    const [resultImage, setResultImage] = useState(null);
    const canvasRef = useRef(null);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setUploadedImage(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleOverlay = () => {
        if (uploadedImage) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            const outputWidth = 400;
            const outputHeight = 400;

            canvas.width = outputWidth;
            canvas.height = outputHeight;

            const img = new Image();
            img.onload = () => {
                // Calculate the scaling factor to fit the image to 400x400 while maintaining aspect ratio
            const scaleFactor = Math.min(outputWidth / img.width, outputHeight / img.height);

            // Calculate the position to center the image on the canvas
            const x = (outputWidth - img.width * scaleFactor) / 2;
            const y = (outputHeight - img.height * scaleFactor) / 2;

            // Draw the uploaded image scaled to 400x400
            ctx.drawImage(img, x, y, img.width * scaleFactor, img.height * scaleFactor);
            const overlayImg = new Image();
            overlayImg.onload = () => {
                // Assuming the overlay image is already 400x400.
                // If not, you would need to repeat the scaling process as above.
                ctx.drawImage(overlayImg, 0, 0, outputWidth, outputHeight);
                setResultImage(canvas.toDataURL('image/png'));
                };
                // Set the src to your overlay image path
                overlayImg.src = longBoiOverlay;
            };
            img.src = uploadedImage;
        }
    };

    return(
        <PaddingContainer
            id = "Home"
            left = "3%"
            right = "10%"
            top = "18%"
            bottom = "10%"
            responsiveLeft = "1rem"
            responsiveRight = "1rem"
            responsiveTop = "10rem"
        >

            <FlexContainer align="center" fullWidthChild>
                {/* -- left-content-- */}
                <motion.div
                    variants = {fadeInLeftVariant}
                    initial = "hidden"
                    whileInView = "visible"
                >
                    <Heading as = "h4" size="h4">$LONG BOI</Heading>

                    <Heading
                        as="h2"
                        size="h2"
                        top="0.5rem"
                        bottom="1rem"
                    >
                        <BlueText>PFP GENERATOR</BlueText>
                        <ShowcaseImageCard>
                            <div>
                                <input type="file" onChange={handleImageUpload} />      
                            </div>
                            <div>
                                <SendButton onClick={handleOverlay}>Generate PFP</SendButton>
                            </div>
                        </ShowcaseImageCard>
                    </Heading>

                    <Heading as = "h4" size = "h4">$LONG BOI SOCIALS</Heading>

                    {/*--social-icons--*/}
                    <FlexContainer 
                        gap="20px" 
                        responsiveFlex
                        justify="center"
                        alignItems="center"
                    >
                    
                        <IconContainer 
                            color="white"
                            size="1.5rem"
                        >
                            <StyledLink href="https://t.me/longpepesol"><BsTelegram /></StyledLink>
                        </IconContainer>

                        <IconContainer 
                            color="white"
                            size="1.5rem"
                        >
                            <StyledLink href="https://twitter.com/longboiOnSOL"><BsTwitter /></StyledLink>
                        </IconContainer>

                        <IconContainer 
                            color="white"
                            size="1.5rem"
                        >
                        
                        </IconContainer>
                    </FlexContainer>
                </motion.div>

                {/*--right-content--*/}
                <FlexContainer
                    justify = "flex-end"
                    as = {motion.div}
                    variants = {fadeInRightVariant}
                    initial = "hidden"
                    whileInView = "visible"
                    
                >
                    <ShowcaseParticleContainer>
                        <Heading
                            as="h3"
                            size="h3"
                        >
                            <BlueText>CONGRATULATIONS ON NO LONGER BEING</BlueText> short!
                            {resultImage && <img src={resultImage} alt="Result" />}
                            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>

                            
                        </Heading>

                        <ParaText 
                            as="p"
                            top="2rem"
                            bottom="2rem"
                        >
                        </ParaText>
                        <Heading
                            as = "h4"
                            size = "h4"
                            justify = "center"
                            
                        ><Heading as="h5" size="h5">this was created for all the long bois out there, but feel free to support and donate: <BlueText>DY9X2EvMjsP6iv1pU8nJ5WvWg8hUs36XTVDECi3a67wK</BlueText></Heading>
                        </Heading>
                            <Heading
                                as="h2"
                                size="h2"
                                top="0.5rem"
                                bottom="1rem"
                            >
                                
                        </Heading>

                        {/* <Particle 
                            //Motion Animation for Image
                            /*as = {motion.img}
                            animate = {{
                                x: [0, 100, 0],
                                rotate: 360,
                                scale: [1, 0.5, 1]
                            }}
                            transition = {{
                                duration: 20,
                                repeate: Infinity,
                            }}
                            src={WSSLogo}
                            width = "135px"
                            height = "auto"
                            alt="logo1"
                            top="400px"
                            left="200px"
                        />

                        <Particle 
                            //Motion Animation for Image
                            /*as = {motion.img}
                            animate = {{
                                y: [0, 100, 0],
                                rotate: 360,
                                scale: [1, 0.8, 1]
                            }}
                            transition = {{
                                duration: 18,
                                repeate: Infinity,
                            }}
                            src={USAFLogo}
                            width = "135px"
                            height = "auto"
                            alt="particle"
                            top="400px"
                            left="350px"
                        />

                        <Particle
                            //Motion Animation for Image
                            /*as = {motion.img}
                            animate = {{
                                y: [0, -100, 0],
                                rotate: 360,
                                scale: [1, 0.9, 1]
                            }}
                            transition = {{
                                duration: 15,
                                repeate: Infinity,
                            }}
                            src={PitchersLogo}
                            width = "135px"
                            height = "auto"
                            alt="particle"
                            bottom="10px"
                            left="-70px"
                            rotate="50deg"
                        /> */}
                    </ShowcaseParticleContainer>
                </FlexContainer>
            </FlexContainer>

        </PaddingContainer>
    )
}

export default Showcase