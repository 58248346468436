import styled from "styled-components";
import { keyframes } from 'styled-components';

export const TickerContainer = styled.div`
    display: flex;
    overflow: hidden;
    white-space: nowrap;
`

export const scrollAnimation = keyframes`
    0% { transform: translateX(50%); }
    100% { transform: translateX(-100%); }
`

export const AnimatedTicker = styled(TickerContainer)`
    display: inline-flex;
    animation: ${scrollAnimation} 15s linear infinite;
`

export const TickerItem = styled.div`
    display: inline-block;
    padding-top: 10px;
    font-family: Handjet;
    text-shadow: 0 0 5px #fbffa6;
    background: linear-gradient(0deg, rgba(192,198,45,1) 0%, rgba(232,255,0,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3rem;

`

export const TicketWrapper = styled.div`
    flex-shrink: 0;
    background-image: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5046393557422969) 10%, rgba(0,0,0,0.4962359943977591) 90%, rgba(0,0,0,0) 100%);
    flex-grow: 0;
    margin: 0 auto;
    width: 50%;
    overflow: hidden;
    border-right: 5px solid rgba(255,255,255,55%);
    border-left: 5px solid rgba(255,255,255,55%);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
`

export const NavbarContainer = styled.div`
    position: fixed;
    align-items: center;
    width: 100%;
    height: 16%;
    top: 0;
    left: 0;
    z-index: 10;
    transition: all 0.3s ease-in;
    background-color: ${({ bgColor }) => bgColor };

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 25%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.75) 75%, rgba(255,255,255,0) 100%);
    }
`

export const Logo = styled.img`
    width: 45px;
    height: auto;
`

export const MenuIcon = styled.a`
    color: ${({ theme }) => theme.colors.secondary };
    font-size: 1.6rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
`

export const NavMenuContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primary };
    z-index: 1;
`

export const MenuItem = styled.a`
    color: #fff;
    font-size: 2.5rem;
    margin-top: 3rem;
    cursor: pointer;
    text-decoration: none;
`