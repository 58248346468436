import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

//import global styles
import {
    FlexContainer,
    PaddingContainer,
    Container,
} from '../styles/Global.styled';

//import Navbar styles
import {
    NavbarContainer,
    Logo,
    MenuIcon,
    TickerItem,
    AnimatedTicker,
    TicketWrapper
} from '../styles/Navbar.styled';

import { GiHamburgerMenu } from 'react-icons/gi';
//import { theme } from '../utils/Theme';
import NavMenu from './layouts/NavMenu';
import CTLogo from '../assets/CTLogo.png';

const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const [ sticky, setSticky] = useState(false);

    useEffect(() => {
        //onScroll function
        const onScroll = () => {
            window.scrollY > 50 ? setSticky(true) : setSticky(false);
        }

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    },[])

    const items = [
        {id: 1, content: '~~~ $LONG boi PFP Generator!'},
        {id: 2, content: ' ~~~ CA: BCzBBz5c6fCcRU7UjdJxszaECh7FdnU551MoQewpQv7L ~~~'}
    ];

    return (
        <NavbarContainer
            bgColor = { sticky ? 'rgba(47, 47, 47, 0.8)' : 'transparent' }
        >
            <PaddingContainer
                top ="1.2rem"
                bottom = "1.2rem"
                responsiveLeft = "1rem"
                responsiveRight = "1rem"
            >
                <Container>
                    <FlexContainer
                        justify = "space-between"
                        responsiveFlex
                    >
                        {/*--left-logo--*/}
                        <Logo
                            src = {CTLogo}
                        >    
                        </Logo>

                            {/*--Ticker--*/}
                            <TicketWrapper
                                responsiveFlex    
                            >
                                <AnimatedTicker>
                                    {items.map(item => (
                                        <TickerItem key={item.id}>{item.content}</TickerItem>
                                    ))}
                                </AnimatedTicker>
                            </TicketWrapper>
                                

                        {/*--right-menu-icon--*/}
                        <MenuIcon
                            as = {motion.a}
                            whileHover = {{scale: 1.2}}
                            onClick = {() => { setOpenMenu(true) }}
                        >
                            <GiHamburgerMenu />
                        </MenuIcon>

                    </FlexContainer>
                </Container>

                <AnimatePresence>
                    {openMenu && <NavMenu setOpenMenu = {setOpenMenu} />}
                </AnimatePresence>
                
            </PaddingContainer>
        </NavbarContainer>
    )
}

export default Navbar