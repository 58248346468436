export const theme = {
    colors: {
        primary: '#2F2F2F',
        primary_light: '#484848',
        secondary: '#1EBF85',
        white: '#FFFFFF',
        black: '#000',
        para_text_color: '#D2D2D2',
        background: 'linear-gradient(90deg, rgba(171,171,171,1) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 75%, rgba(171,171,171,1) 100%)'
        
    },
    fonts: {
        family: 'DM Sans',
        weight: {
            regular: 400,
            medium: 500,
            bold: 700,
        }
    },
    breakpoints: {
        mobile: '720px',
    }
}