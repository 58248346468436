import styled from "styled-components";
//import { SkillsCard } from "./MySkills.styled";

export const MainBody = styled.div`
    background: linear-gradient(25deg, rgba(19,19,19,1) 0%, rgba(45,45,45,1) 100%);
  
`

export const Container = styled.div`
    width: 90%;
    max-width: 1280px;
    margin: auto;
`

export const PaddingContainer = styled.div`
    padding-top: ${({ top }) => top};
    padding-bottom: ${({ bottom }) => bottom};
    padding-left: ${({ left}) => left};
    padding-right: ${({ right }) => right};
    

    @media(max-width: ${({ theme }) => theme.breakpoints.mobile}){
        padding-top: ${({ responsiveTop }) => responsiveTop};
        padding-bottom: ${({ responsiveBottom }) => responsiveBottom};
        padding-left: ${({ responsiveLeft}) => responsiveLeft};
        padding-right: ${({ responsiveRight }) => responsiveRight};
    }
`

export const FlexContainer = styled.div`
    display: flex;
    justify-content: ${({ justify }) => justify};
    align-items: ${({ align }) => align};
    gap: ${({ gap }) => gap};
    flex-direction: ${({ direction }) => direction};

    & > div {
        flex: ${({ fullWidthChild }) => fullWidthChild && 1};
    }

    @media(max-width: ${({ theme }) => theme.breakpoints.mobile}){
        display: ${({ responsiveFlex }) => responsiveFlex ? 'flex': 'block'};

        flex-direction: ${({ responsiveDirection }) => responsiveDirection };
    }
`

export const LogoContainer = styled(FlexContainer)`
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(72, 72, 72, 0.75) 50%, rgba(72, 72, 72, 1) 100%);
    padding: 20px;
    border-radius: 25px;
    border: 1px solid #fff;
    transition: background .5s;

    &:hover {
        background: linear-gradient(0deg, rgba(12,83,58,1) 0%, rgba(30,191,133,1) 100%);
        transition: 1s;
        filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 15px #fff);
    }

    &:not(:hover) {
        transition: 1s;
    }
    
`

export const Heading = styled(PaddingContainer)`
    color: ${({ theme }) => theme.colors.white};
    text-align: ${({ align }) => align};
    font-size: ${({ size }) => {
        switch(size){
            case 'h1':
                return '4.5rem';
            
            case 'h2':
                return '3rem';
            
            case 'h3':
                return '2rem';
            
            case 'h4':
                return '1.2rem';

            default:
                return;
        }
    }};

    @media(max-width: ${({ theme }) => theme.breakpoints.mobile }){
        font-size: ${({ size }) => {
            switch(size){
                case 'h1':
                    return '2.5rem';
                
                case 'h2':
                    return '2rem';
                
                case 'h3':
                    return '1.5rem';
                
                case 'h4':
                    return '1rem';
    
                default:
                    return;
            }
        }}
    }
`

export const BlueText = styled.span`
    color: ${({ theme }) => theme.colors.secondary};
`

export const ParaText = styled(PaddingContainer)`
    color: ${({ theme }) => theme.colors.para_text_color};
    line-height: 2rem;
`

export const IconContainer = styled.div`
    font-size: ${({ size }) => size};
    cursor: pointer;
    color: ${({ color, theme }) => {
        switch(color){
            case 'white':
                return theme.colors.white;

            case 'blue':
                return theme.colors.secondary;

            default:
                return;
        }
    }}
`
export const SendButton = styled.button`
    display: inline-block;
    width: max-content;
    padding: 1rem 2rem;
    color: ${({ theme }) => theme.colors.white };
    background-color: ${({ theme }) => theme.colors.primary_light };
    border: 1px solid ${({ theme }) => theme.colors.gray };
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
        background: ${({ theme }) => theme.colors.secondary};
        transition: 1s;
        filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 15px #fff);
    }
    
    &:not(:hover) {
        transition: 1s;
    }
`

export const Button = styled.a`
    display: inline-block;
    width: max-content;
    padding: 1rem 2rem;
    color: ${({ theme }) => theme.colors.white };
    background-color: ${({ theme }) => theme.colors.primary_light };
    border: 1px solid ${({ theme }) => theme.colors.gray };
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
        background: ${({ theme }) => theme.colors.secondary};
        transition: 1s;
        filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 15px #fff);
    }
    
    &:not(:hover) {
        transition: 1s;
    }
`

export const InnerLogoContainer = styled(IconContainer)`
    cursor: pointer;

    

    ${LogoContainer}:hover & {
        transform: scale(1.2);
        transition: 1s;
    }

    ${LogoContainer}:not(:hover) & {
        transform: scale(1);
        transition: 1s;
    }
`


